import React,{useState} from 'react';
import Button from '@mui/material/Button';
import { TextField } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { purple, blue } from '@mui/material/colors';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { useTheme } from '@emotion/react';

const theme = createTheme({
  palette: {
    primary: purple,
    background: {
      default: "black"
    }
  },
});

const filter = createFilterOptions();

const regions = [
  { region: 'us-east-1' },
  { region: 'us-east-2' },
  { region: 'us-west-1' },
  { region: 'us-west-2' },
  { region: 'af-south-1' },
  { region: 'ap-east-1' },
  { region: 'ap-south-1' },
  { region: 'ap-northeast-3' },
  { region: 'ap-northeast-2' },
  { region: 'ap-southeast-1' },
  { region: 'ap-southeast-2' },
  { region: 'ap-northeast-1' },
  { region: 'ca-central-1' },
  // { region: 'cn-north-1' },
  // { region: 'cn-northwest-1' },
  { region: 'eu-central-1' },
  { region: 'eu-west-1' },
  { region: 'eu-west-2' },
  { region: 'eu-south-1' },
  { region: 'eu-west-3' },
  { region: 'eu-north-1' },
  { region: 'me-south-1' },
  { region: 'sa-east-1' },
];

const ec2_types = [
 { ec2_type: 'm5.large' },
];

const os_type = [
  { os: 'linux' },
  { os: 'windows' }
];

const initialState = [
  {ec2_type: "m6i.large"},
  {ec2_type: "c6i.large"},
  {ec2_type: "r6i.large"},
  {ec2_type: "t3.large"},
  {ec2_type: "m6i.xlarge"},
  {ec2_type: "c6i.xlarge"},
  {ec2_type: "r6i.xlarge"},
  {ec2_type: "t3.xlarge"},
];


const Form = (props) => {
  const [instances,setInstances] = useState(initialState);
  const [region, setRegion] = useState('');
  const [os, setOs] = useState('');

  // console.log(instances)
  async function onChangeHandle(value) {
    // console.log(value)
    const region = value
    const apiData = await fetch(`https://u27tg888eb.execute-api.us-east-1.amazonaws.com/dev/instances/${region}/`)
      .then( res => res.json())
      // console.log(apiData)
      if (region) {
        setInstances(apiData)
        // console.log(`AFTER, ${instances}`)
      } else {
        setInstances({
          error: 'Please select region',
        }
        )}
    }
  const theme = useTheme();
  const styles = {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
  };

      return (
        <ThemeProvider theme={theme}>
        <form onSubmit={ props.getSpot } onClick={props.handleSubmit}>
            <Autocomplete
              onChange={(event, value) => onChangeHandle(value)}
              id="region"
              freeSolo
              options={regions.map((option) => option.region)}
              renderInput={(params) => <TextField
                {...params} label="region"
                color="secondary" focused 
                type='text'
                placeholder='region'
                name='region'
                variant="filled"
            />}
            />
            <Autocomplete
              id="ec2_type"
              freeSolo
              options={instances.map((option) => option.ec2_type)}
              renderInput={(params) => <TextField
                {...params} label="ec2_type"
                color="secondary" focused 
                type='text'
                placeholder='ec2 type'
                name='ec2_type'
                variant="filled"
                />}
            />
            <Autocomplete
              id="os_type"
              freeSolo={false}
              popupIcon={""}
              options={os_type.map((option) => option.os)}
              renderInput={(params) => <TextField
                {...params} label="os_type"
                color="secondary" focused 
                type='text'
                placeholder='os type'
                name='os_type'
                variant="filled"
                />}
            />
            <br />
            <Button
            type="submit"
            color="secondary"
            variant="outlined"
            onClick={props.onSubmit}
            >Submit</Button>
        </form>
        </ThemeProvider>
        )
      }

export default Form; 