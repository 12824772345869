import React, { useState, useEffect } from "react";
import "./App.css";
import Form from "./Form";
import Spot from "./Spot";
import Od from "./Od";
import VcpuMem from "./VcpuMem";
import Scores from "./Scores";
import RiJsonDataDisplay from "./RiJsonDataDisplay";
import MarketJsonDataDisplay from "./MarketJsonDataDisplay";
import SpJsonDataDisplay from "./SpJsonDataDisplay";
import Typography from "@mui/material/Typography";
import { createTheme } from "@mui/material/styles";
import LinearProgress from "@mui/material/LinearProgress";
import { Helmet } from "react-helmet";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { CircularProgress } from "@mui/material";
import { Button } from "@mui/material";
import EC2MarketPlace from "./EC2MarketPlace";
import CheapestVcpuSpot from "./CheapestVcpuSpot";
import NavBar from "./NavBar";
import { Route, Routes } from "react-router";
import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";
import { Grid } from "@mui/material";
import Paper from "@mui/material/Paper";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import ViewInArTwoToneIcon from "@mui/icons-material/ViewInArTwoTone";
import SpotAnalyzer from "./CheapestSpotInstanceFinder";
import Chip from "@mui/material/Chip"; // Import the Chip component
import InsightsIcon from "@mui/icons-material/Insights";

const api_gw = "https://wzstkt5ta4.execute-api.us-east-1.amazonaws.com/dev-v2";

const TITLE = "EC2 Hub";

const lightTheme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#3f51b5",
    },
    secondary: {
      main: "#f50057",
    },
  },
});

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

const initialState = {
  ec2_type: "m5.large",
};

function App() {
  const [state, setState] = useState({});
  const [on_demand, set_on_demand] = useState({});
  const [spot, set_spot] = useState({});
  const [reserved_instance, set_reserved_instance] = useState({});
  const [savings_plan, set_savings_plan] = useState({});
  const [marketplace, set_marketplace] = useState({});
  const [instances, set_instances] = useState(initialState);

  const [isLoading, setLoading] = useState(false);
  const [marketLoading, setMarketLoading] = useState(false);
  const [odLoading, setOdLoading] = useState(false);
  const [spotLoading, setSpotLoading] = useState(false);
  const [riLoading, setRiLoading] = useState(false);
  const [spLoading, setSpLoading] = useState(false);
  const [vcpu, setVcpu] = useState({});
  const [scores, setScores] = useState({});
  const [scoresLoading, setScoresLoading] = useState(false);
  const [error, setError] = useState(null);

  async function fetchOnDemand(e) {
    try {
      setError(null);
      const region = e.target.elements.region.value;
      const ec2_type = e.target.elements.ec2_type.value;
      const os_type = e.target.elements.os_type.value;
      setOdLoading(true);
      e.preventDefault();
      const response = await fetch(
        `${api_gw}/od/${region}/${ec2_type}/${os_type}/`
      );

      if (!response.ok) {
        throw new Error("Server error");
      }

      const apiData = await response.json();

      if (region && ec2_type && os_type) {
        set_on_demand({
          od_rate: apiData.rate,
        });
        setOdLoading(false);
      } else {
        setError("Oops, something went wrong. Try again.");
      }
    } catch (err) {
      setError("Oops, something went wrong. Try again.");
    }
  }

  async function fetchSpot(e) {
    try {
      setError(null);
      const region = e.target.elements.region.value;
      const ec2_type = e.target.elements.ec2_type.value;
      const os_type = e.target.elements.os_type.value;
      setSpotLoading(true);
      e.preventDefault();

      const response = await fetch(
        `${api_gw}/spot/${region}/${ec2_type}/${os_type}/`
      );

      if (!response.ok) {
        throw new Error("Server error");
      }

      const apiData = await response.json();

      if (region) {
        set_spot({
          spot_rate: apiData.rate,
        });
        setSpotLoading(false);
      }
    } catch (err) {
      setError("Oops, something went wrong. Try again.");
      setSpotLoading(false);
    }
  }

  async function fetchRi(e) {
    try {
      setError(null);
      const region = e.target.elements.region.value;
      const ec2_type = e.target.elements.ec2_type.value;
      const os_type = e.target.elements.os_type.value;
      setRiLoading(true);
      e.preventDefault();

      const response = await fetch(
        `${api_gw}/ri/${region}/${ec2_type}/${os_type}/`
      );

      if (!response.ok) {
        throw new Error("Server error");
      }

      const apiData = await response.json();

      if (region) {
        set_reserved_instance({
          ri: apiData,
        });
        setRiLoading(false);
      }
    } catch (err) {
      setError("Oops, something went wrong. Try again.");
      setRiLoading(false);
    }
  }

  async function fetchSp(e) {
    try {
      setError(null);
      const region = e.target.elements.region.value;
      const ec2_type = e.target.elements.ec2_type.value;
      const os_type = e.target.elements.os_type.value;
      setSpLoading(true);
      e.preventDefault();

      const response = await fetch(
        `${api_gw}/sp/${region}/${ec2_type}/${os_type}/`
      );

      if (!response.ok) {
        throw new Error("Server error");
      }

      const apiData = await response.json();

      if (region) {
        set_savings_plan({
          sp: apiData,
        });
        setSpLoading(false);
      }
    } catch (err) {
      setError("Oops, something went wrong. Try again.");
      setSpLoading(false);
    }
  }

  async function fetchMarket(e) {
    try {
      setError(null);
      const region = e.target.elements.region.value;
      const ec2_type = e.target.elements.ec2_type.value;
      const os_type = e.target.elements.os_type.value;
      setMarketLoading(true);
      e.preventDefault();

      const response = await fetch(
        `${api_gw}/market/${region}/${ec2_type}/${os_type}/`
      );

      if (!response.ok) {
        throw new Error("Server error");
      }

      const apiData = await response.json();

      if (region) {
        set_marketplace({
          market: apiData,
        });
        setMarketLoading(false);
      }
    } catch (err) {
      setError("Oops, something went wrong. Try again.");
      setMarketLoading(false);
    }
  }

  async function fetchInstances(e) {
    try {
      setError(null);
      const region = e.target.elements.region.value;
      e.preventDefault();

      if (!region) {
        setError("Please select a region.");
        return;
      }

      const response = await fetch(`${api_gw}/instances/${region}/`);

      if (!response.ok) {
        throw new Error("Server error");
      }

      const apiData = await response.json();
      set_instances({
        array: apiData,
      });
    } catch (err) {
      setError("Oops, something went wrong. Try again.");
    }
  }

  async function fetchVcpu(e) {
    try {
      setError(null);
      const ec2_type = e.target.elements.ec2_type.value;
      e.preventDefault();

      if (!ec2_type) {
        setError("Please select an EC2 type.");
        return;
      }

      const response = await fetch(`${api_gw}/vcpu-mem/${ec2_type}/`);

      if (!response.ok) {
        throw new Error("Server error");
      }

      const apiData = await response.json();
      setVcpu({
        array: apiData,
      });
    } catch (err) {
      setError("Oops, something went wrong. Try again.");
    }
  }

  async function fetchScores(e) {
    try {
      setError(null);
      const region = e.target.elements.region.value;
      const ec2_type = e.target.elements.ec2_type.value;
      const os_type = e.target.elements.os_type.value;
      setScoresLoading(true);
      e.preventDefault();

      if (!region || !ec2_type || !os_type) {
        setError("Please ensure all fields are filled out.");
        return;
      }

      const response = await fetch(
        `${api_gw}/scores/${ec2_type}/${region}/${os_type}/`
      );

      if (!response.ok) {
        throw new Error("Server error");
      }

      const apiData = await response.json();
      setScores({
        scores: apiData,
      });
      setScoresLoading(false);
    } catch (err) {
      setError("Oops, something went wrong. Try again.");
      setScoresLoading(false);
    }
  }

  async function fetchData(e) {
    fetchInstances(e);
    fetchOnDemand(e);
    fetchSpot(e);
    fetchRi(e);
    fetchSp(e);
    fetchMarket(e);
    fetchVcpu(e);
    fetchScores(e);
    // return console.log("fetch complete")
  }

  async function fetchRiData(e) {
    fetchInstances(e);
    fetchOnDemand(e);
    fetchMarket(e);
    // return console.log("fetch complete")
  }

  const [darkMode, setDarkMode] = useState(true);
  const handleToggle = () => {
    setDarkMode(!darkMode);
  };

  useEffect(() => {
    ReactGA.initialize("G-0W0H3EM6F1");
  }, []);

  const location = useLocation();
  const [showDescription, setShowDescription] = useState(true);
  const handleSubmit = () => {
    setShowDescription(false);
    // Other submit logic here
  };

  const dataLayer = [];

  // return (
  //   <div className="App">
  //     <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
  //       <NavBar darkMode={darkMode} handleToggle={handleToggle} />
  //       <Routes>
  //         <Route path="/EC2MarketPlace" element={<EC2MarketPlace getRi={fetchRiData} />} />
  //         <Route path="/CheapestVcpuSpot" element={<CheapestVcpuSpot/>} />
  //         <Route path="/SpotAnalyzer" element={<SpotAnalyzer/>} />
  //       </Routes>
  //       <CssBaseline />
  //       <Helmet>
  //         <title>{TITLE}</title>
  //         <meta name="description" content="EC2 Pricing Hub is a web app that helps you calculate the cost of running EC2 instances on AWS. Enter your desired region, instance type, and operating system to see pricing information for On-Demand, Reserved Instances, and Spot Instances."></meta>
  //         <script async src="https://www.googletagmanager.com/gtag/js?id=G-0W0H3EM6F1"></script>
  //         <script>{`
  //         window.dataLayer = window.dataLayer || [];
  //         function gtag(){dataLayer.push(arguments)}
  //         gtag('js', new Date());
  //         gtag('config', 'G-0W0H3EM6F1');
  //         `}
  //         </script>
  //       </Helmet>
  //       <br />
  //       {error && (
  //         <div style={{ color: 'purple', textAlign: 'center', marginTop: '20px' }}>
  //       <Typography variant="h5" gutterBottom component="div">
  //         {error}
  //       </Typography>
  //     </div>

  //     )}
  //       <br />
  //       <Grid container spacing={2}>
  //       {location.pathname !== "/EC2MarketPlace" && location.pathname !== "/CheapestVcpuSpot" && location.pathname !== "/SpotAnalyzer" && (

  //         <>
  //           <Grid container item xs={12} justifyContent="center">
  //             <Grid item xs={12} sm={8} md={6}>
  //               <Paper elevation={3}>
  //                 <Box p={2}>
  //                   <Typography variant="h5" component="div" gutterBottom>
  //                     Welcome to the EC2 Pricing Hub! <ViewInArTwoToneIcon color='warning'/>
  //                   </Typography>
  //                 </Box>
  //               </Paper>
  //             </Grid>
  //           </Grid>
  //           {showDescription && (
  //             <Grid container item xs={12} justifyContent="center">
  //               <Grid item xs={12} sm={8} md={6}>
  //                 <Card>
  //                   <CardContent>
  //                     <Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
  //                       Compare and analyze the pricing of EC2 instances across different AWS purchasing options
  //                     </Typography>
  //                     <br />
  //                     <Grid container spacing={2} justifyContent="center" alignItems="flex-end">
  //                       <Grid item xs={6}>
  //                         <Button
  //                           variant="outlined"
  //                           color="primary"
  //                           size="small"
  //                           component={Link}
  //                           target="_blank"
  //                           href="https://aws.amazon.com/ec2/spot/"
  //                         >
  //                           Learn More about EC2 Spot
  //                         </Button>
  //                       </Grid>
  //                       <Grid item xs={6}>
  //                         <Button
  //                           variant="outlined"
  //                           color="primary"
  //                           size="small"
  //                           component={Link}
  //                           target="_blank"
  //                           href="https://docs.aws.amazon.com/AWSEC2/latest/UserGuide/ri-market-general.html"
  //                         >
  //                           What is the AWS RI Marketplace
  //                         </Button>
  //                       </Grid>
  //                     </Grid>
  //                   </CardContent>
  //                 </Card>
  //               </Grid>
  //             </Grid>
  //           )}
  //         </>
  //       )}

  // {location.pathname !== "/EC2MarketPlace" && location.pathname !== "/CheapestVcpuSpot" && location.pathname !== "/SpotAnalyzer" && (
  //           <>
  //             <Grid container item xs={12} justifyContent="center">
  //               <Grid item xs={12} sm={6}>
  //                 {odLoading && <LinearProgress color="success" size={100} />}
  //                 <Form getSpot={fetchData} instance={instances.array} onSubmit={handleSubmit} />
  //               </Grid>
  //             </Grid>
  //             {on_demand.od_rate && (
  //               <Grid item xs={12} sm={6}>
  //                 <Od od_rate={on_demand.od_rate} error={state.error} />
  //               </Grid>
  //             )}
  //             {spot.spot_rate && (
  //               <Grid item xs={12} sm={6}>
  //                 <Spot rate={spot.spot_rate} od_rate={on_demand.od_rate} error={state.error} />
  //               </Grid>
  //             )}
  //             {vcpu.array && (
  //               <Grid item xs={12} sm={6}>
  //                 <VcpuMem stats={vcpu} error={state.error} />
  //               </Grid>
  //             )}
  //             {scores.scores && (
  //               <Grid item xs={12} sm={6}>
  //                 <Scores stats={scores} error={state.error} />
  //               </Grid>
  //             )}
  //             <Grid item xs={12}>
  //               {spotLoading && <LinearProgress color="primary" size={68} />}
  //               <RiJsonDataDisplay ri_data={reserved_instance.ri} od_rate={on_demand.od_rate} />
  //               <br />
  //               <br />
  //               <br />
  //               {marketLoading && <CircularProgress color="success" size={68} />}
  //               <MarketJsonDataDisplay market_data={marketplace.market} od_rate={on_demand.od_rate} loading={marketLoading} />
  //               {spLoading && <LinearProgress color="primary" size={68} />}
  //               <br />
  //               <br />
  //               <br />
  //               <SpJsonDataDisplay sp_data={savings_plan.sp} od_rate={on_demand.od_rate} />
  //             </Grid>
  //           </>
  //         )}

  //       </Grid>

  //     </ThemeProvider>
  //     <div style={{ textAlign: 'center', marginTop: '10px' }}>
  //       <Typography variant="body1" component="div" gutterBottom>
  //         Powered by <a href="https://joshdlee.com" target="_blank" rel="noreferrer">Josh Lee</a>
  //       </Typography>
  //     </div>
  //   </div>

  // );

  return (
    <div className="App">
      <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
        <NavBar darkMode={darkMode} handleToggle={handleToggle} />
        <div className="content">
          <Routes>
            <Route
              path="/EC2MarketPlace"
              element={<EC2MarketPlace getRi={fetchRiData} />}
            />
            <Route path="/CheapestVcpuSpot" element={<CheapestVcpuSpot />} />
            <Route path="/SpotAnalyzer" element={<SpotAnalyzer />} />
          </Routes>
          <CssBaseline />
          <Helmet>
            <title>{TITLE}</title>
            <meta
              name="description"
              content="EC2 Pricing Hub is a web app that helps you calculate the cost of running EC2 instances on AWS. Enter your desired region, instance type, and operating system to see pricing information for On-Demand, Reserved Instances, and Spot Instances."
            ></meta>
            <script
              async
              src="https://www.googletagmanager.com/gtag/js?id=G-0W0H3EM6F1"
            ></script>
            <script>
              {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments)}
              gtag('js', new Date());
              gtag('config', 'G-0W0H3EM6F1');
              `}
            </script>
          </Helmet>
          <br />
          {error && (
            <div
              style={{
                color: "purple",
                textAlign: "center",
                marginTop: "20px",
              }}
            >
              <Typography variant="h5" gutterBottom component="div">
                {error}
              </Typography>
            </div>
          )}
          <br />

          {/* Only show these components if the user is NOT on the SpotAnalyzer page */}
          {location.pathname !== "/SpotAnalyzer" && (
            <Grid container spacing={2}>
              {/* Existing Grid Item for On-Demand rate, Spot rate, Vcpu, etc. */}
              <Grid container item xs={12} justifyContent="center">
                <Grid item xs={12} sm={6}>
                  {odLoading && <LinearProgress color="success" size={100} />}
                  <Form
                    getSpot={fetchData}
                    instance={instances.array}
                    onSubmit={handleSubmit}
                  />
                </Grid>
              </Grid>

              {on_demand.od_rate && (
                <Grid item xs={12} sm={6}>
                  <Od od_rate={on_demand.od_rate} error={state.error} />
                </Grid>
              )}

              {spot.spot_rate && (
                <Grid item xs={12} sm={6}>
                  <Spot
                    rate={spot.spot_rate}
                    od_rate={on_demand.od_rate}
                    error={state.error}
                  />
                </Grid>
              )}

              {vcpu.array && (
                <Grid item xs={12} sm={6}>
                  <VcpuMem stats={vcpu} error={state.error} />
                </Grid>
              )}

              {scores.scores && (
                <Grid item xs={12} sm={6}>
                  <Scores stats={scores} error={state.error} />
                </Grid>
              )}

              {/* Conditionally render cards based on available data and loading state */}
              {(spotLoading || reserved_instance.ri) && (
                <Grid item xs={12} sm={6}>
                  <Card>
                    <CardContent>
                      {spotLoading ? (
                        <CircularProgress color="primary" size={68} />
                      ) : (
                        <RiJsonDataDisplay
                          ri_data={reserved_instance.ri}
                          od_rate={on_demand.od_rate}
                        />
                      )}
                    </CardContent>
                  </Card>
                </Grid>
              )}

              {(spLoading || savings_plan.sp) && (
                <Grid item xs={12} sm={6}>
                  <Card>
                    <CardContent>
                      {spLoading ? (
                        <CircularProgress color="primary" size={68} />
                      ) : (
                        <SpJsonDataDisplay
                          sp_data={savings_plan.sp}
                          od_rate={on_demand.od_rate}
                        />
                      )}
                    </CardContent>
                  </Card>
                </Grid>
              )}

              {(marketLoading || marketplace.market) && (
                <Grid item xs={12}>
                  <Card>
                    <CardContent>
                      {marketLoading ? (
                        <CircularProgress color="success" size={68} />
                      ) : (
                        <MarketJsonDataDisplay
                          market_data={marketplace.market}
                          od_rate={on_demand.od_rate}
                          loading={marketLoading}
                        />
                      )}
                    </CardContent>
                  </Card>
                </Grid>
              )}
            </Grid>
          )}
        </div>
        <div className="footer">
          <a
            href="https://joshdlee.com"
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none" }}
          >
            <Chip
              icon={<InsightsIcon />} // Optional icon
              label="Powered by Josh Lee"
              variant="outlined"
              clickable
            />
          </a>
        </div>
      </ThemeProvider>
    </div>
  );
}
export default App;
